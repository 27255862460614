<template>
  <div class="search middle">
    <div
      v-if="bundleStore.firstLoaded && !searchLoading"
      class="data-plans-group"
      ref="searchResult"
    >
      <div class="container">
        <div class="search-row">
          <h5>Data Plans and prices</h5>
          <button class="search-back" @click="bundleStore.clearBundles">
            <SearchIcon class="search-back__icon" />
          </button>
        </div>
        <div v-if="bundleStore.bundles.length" class="data-plans-slider">
          <BundleCard
            v-for="(bundle, index) in bundleStore.bundles"
            :key="bundle.id"
            :bundle="bundle"
            :requested-countries="bundleStore.countries"
            :primary="index === 0"
          />
        </div>
        <div v-else class="d-flex flex-column align-items-start">
          <p class="empty-text mb-4">
            Unable to find esim for your request. Please try again
          </p>
        </div>
        <div class="d-flex flex-column align-items-center">
          <button
            class="btn btn-b btn-danger mt-4"
            @click="bundleStore.clearBundles"
          >
            New search
          </button>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <!-- <h5 v-if="!isMobile" class="search__title">Buy eSIM online</h5>
      <p v-if="!isMobile" class="search__description">
        Buy eSIMs hassle-free on our website. Get a taste of convenience with
        eSIMs starting at just from $10 for the US. Experience seamless
        connectivity without physical cards. Get the eSIM that suits your needs
        online!
      </p> -->
      <h1 class="search__label">Where are you going?</h1>
      <SearchCountries v-model="bundleStore.countries" />
      <p class="search__label">How long do you need the eSIM?</p>
      <div class="search-bottom-row">
        <SearchDuration v-model="bundleStore.duration" />
        <button
          class="btn btn-b btn-danger search__find"
          :disabled="
            !bundleStore.duration ||
            !bundleStore.countries.length ||
            searchLoading
          "
          @click="searchBundles"
        >
          Find me the best offer
          <MainLoader v-if="searchLoading" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SearchCountries from "@/components/search/SearchCountries.vue";
import SearchDuration from "@/components/search/SearchDuration.vue";
import { nextTick, ref, Ref } from "vue";
import MainLoader from "@/components/MainLoader.vue";
import { useBundleStore } from "@/stores/bundle";
import BundleCard from "@/components/bundle/BundleCard.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";

const bundleStore = useBundleStore();

const searchLoading: Ref<boolean> = ref(false);
const searchResult: Ref<HTMLDivElement | null> = ref(null);

async function searchBundles(): Promise<void> {
  searchLoading.value = true;
  await bundleStore.getBundles(
    bundleStore.duration || 1,
    bundleStore.countries
  );
  searchLoading.value = false;

  await nextTick(() => {
    if (searchResult.value) {
      setTimeout(
        () =>
          document.documentElement.scrollTo({
            top: (searchResult.value?.offsetTop || 30) - 30,
            behavior: "smooth",
          }),
        100
      );
    }
  });
}
</script>

<style scoped lang="sass">
@import '@/assets/sass/variables'

.search
  &-row
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 16px

    h5
      margin-bottom: 0

  &-back
    padding: 0
    margin: 0
    box-sizing: border-box
    outline: none
    cursor: pointer
    border: none
    background: none

    &__icon
      width: 25px
      height: auto
      fill: $primaryColor

  &__title
    font-size: 40px
    font-weight: bold
    margin-bottom: 16px

  &__description
    font-size: 20px
    font-weight: 500
    margin-bottom: 40px
    max-width: 1050px

  &__label
    font-size: 24px
    font-weight: 600
    margin-bottom: 30px

    @media (max-width: 600px)
      font-size: 18px
      margin-bottom: 20px

  &__find
    margin-left: auto

    @media (max-width: 600px)
      width: 100%

  &-bottom-row
    margin-bottom: 100px
    display: flex
    align-items: stretch
    gap: 16px

    @media (max-width: 600px)
      flex-direction: column
</style>
