<template>
  <div class="page">
    <Transition name="fade">
      <AppLoader v-if="appStore.firstLoading" />
    </Transition>
    <div class="notifications">
      <TransitionGroup name="fade">
        <MainNotification
          v-for="(notification, index) in appStore.notifications"
          :key="index"
          :notification="notification"
        />
      </TransitionGroup>
    </div>
    <Transition name="popup">
      <PopupAuth v-if="isAuth" />
    </Transition>
    <TransitionGroup name="popup">
      <component
        v-for="(popup, index) in appStore.popups"
        :is="popup.component"
        :key="index"
        v-bind="popup.props"
      />
    </TransitionGroup>
    <MainHeader />
    <router-view />
    <MainFooter v-if="!isMobile" />
    <MobileNavBar v-else-if="userStore.isAuth" />
  </div>
</template>

<script setup lang="ts">
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import { useUserStore } from "@/stores/user";
import MobileNavBar from "@/components/MobileNavBar.vue";
import { detectMobile } from "@/helpers";
import { useAppStore } from "@/stores/app";
import MainNotification from "@/components/MainNotification.vue";
import AppLoader from "@/components/AppLoader.vue";
import { computed, ComputedRef } from "vue";
import router from "@/router";
import PopupAuth from "@/components/popups/PopupAuth.vue";

const appStore = useAppStore();
const userStore = useUserStore();

const isMobile: boolean = detectMobile();

const isAuth: ComputedRef<boolean> = computed(() =>
  router.currentRoute.value.fullPath.includes("auth")
);
</script>

<style>
@import url("@/assets/css/bottstrap.min.css");
</style>
<style lang="sass">
@import "@/assets/sass/main"

.notifications
  position: fixed
  bottom: 20px
  right: 20px
  z-index: 999999
  display: flex
  flex-direction: column
  width: calc(100% - 40px)
  max-width: 300px
  gap: 10px

  @media (max-width: 400px)
    max-width: 100%
</style>
