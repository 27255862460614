<template>
  <PopupTemplate class="device-popup">
    <p class="popup__title">Check your device compatibility</p>
    <p class="popup__description">
      if your device is not listed you will not be able to install an eSIM.
      Unsure of your device model? Find out by clicking
      <a href="https://toosim.com/esim-compatible" target="_blank">here</a>
    </p>
    <div class="button-toggle-group">
      <button
        v-for="(device, index) in dataStore.supportedDevices"
        :key="index"
        class="button-toggle"
        :class="{ active: activeDevice === index }"
        @click="activeDevice = index"
      >
        {{ device.name }}
      </button>
    </div>
    <div v-if="dataStore.supportedDevices.length" class="device-list">
      <div
        v-for="(model, index) in dataStore.supportedDevices[activeDevice]
          .models"
        :key="index"
        class="device"
      >
        {{ model }}
      </div>
    </div>
  </PopupTemplate>
</template>

<script setup lang="ts">
import PopupTemplate from "@/components/popups/PopupTemplate.vue";
import { useDataStore } from "@/stores/data";
import { ref, Ref } from "vue";

const dataStore = useDataStore();
const activeDevice: Ref<number> = ref(0);
</script>

<style scoped lang="sass">
@import '@/assets/sass/variables'

.device
  padding: 8px 12px
  font-weight: 500
  border-radius: 6px
  background-color: $secondaryContainerColor

  &-list
    padding-top: 24px
    display: flex
    flex-direction: column
    gap: 10px
    overflow-y: auto
</style>
