import { computed, ComputedRef } from "vue";
import { Country, useDataStore } from "@/stores/data";
import router from "@/router";
import { NotificationType, useAppStore } from "@/stores/app";

const months: Record<number, string> = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export function checkEmailIsValid(email: string): boolean {
  return !!email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function dateToString(date: Date): string {
  const day: number = date.getDate();
  const month: number = date.getMonth() + 1;

  const dayString: string = day < 10 ? `0${day}` : day.toString();

  return `${dayString} ${months[month]}.`;
}

export function detectMobile(): boolean {
  const toMatch: RegExp[] = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem: RegExp) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

export function getCountryImageSrc(iso: string): string {
  try {
    return require(`@/assets/images/countries/${iso.toLowerCase()}.svg`);
  } catch (e) {
    return require(`@/assets/images/countries/unknown.svg`);
  }
}

export function requestedCountriesFromUrl(): Country[] {
  const countries: Country[] = [];
  const dataStore = useDataStore();

  let queryCountriesIso: string[] = [];
  try {
    const firstSplit = window.location.href.split("requestedCountries=");
    if (firstSplit.length > 1) {
      const secondSplit = firstSplit[1].split("#");
      const jsonCountries = secondSplit[0];
      const countriesIso = JSON.parse(decodeURI(jsonCountries));
      if (Array.isArray(countriesIso)) {
        queryCountriesIso = countriesIso;
      }
    }
  } catch (e) {
    console.log("ERROR ", e);
  }

  if (Array.isArray(queryCountriesIso)) {
    queryCountriesIso.forEach((iso: string): void => {
      const country: Country | undefined = dataStore.countries.find(
        (c: Country) => c.iso.toLowerCase() === iso.toLowerCase()
      );
      if (country) {
        countries.push(country);
      }
    });
  }

  return countries;
}

export function copyToClipBoard(text: string): void {
  const appStore = useAppStore();

  try {
    navigator.clipboard.writeText(text);
    appStore.showNotification({
      notification: { text: "Code copied", type: NotificationType.success },
      milliseconds: 1500,
    });
  } catch (e) {
    appStore.showNotification({
      notification: { text: "Code copy error", type: NotificationType.error },
      milliseconds: 1500,
    });
  }
}
