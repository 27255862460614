<template>
  <footer class="footer" role="contentinfo">
    <div class="container">
      <div class="row">
        <div class="col-lg-2 col-md-4 col-6">
          <h6>Popular Countries</h6>
          <ul>
            <li>
              <a href="https://toosim.com/esim-united-states">United States</a>
            </li>
            <li><a href="https://toosim.com/esim-united-kingdom">The UK</a></li>
            <li><a href="https://toosim.com/esim-spain">Spain</a></li>
            <li><a href="https://toosim.com/esim-italy">Italy</a></li>
            <li>
              <a href="https://toosim.com/esim-united-arab-emiraes">UAE</a>
            </li>
            <li><a href="https://toosim.com/esim-australia">Australia</a></li>
            <li><a href="https://toosim.com/esim-germany">Germany</a></li>
            <li><a href="https://toosim.com/esim-france">France</a></li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-4 col-6">
          <h6>Regional eSIMs</h6>
          <ul>
            <li><a href="https://toosim.com/esim-europe">Europe eSIMs</a></li>
            <li>
              <a href="https://toosim.com/esim-north-america">North-america</a>
            </li>
            <li>
              <a href="https://toosim.com/esim-middle-east"
                >Middle East eSIMs</a
              >
            </li>
            <li><a href="https://toosim.com/esim-asia">Asia eSIMs</a></li>
            <li>
              <a href="https://toosim.comesim-south-america">South America</a>
            </li>
            <li><a href="https://toosim.com/esim-oceania">Oceania eSIMs</a></li>
            <li><a href="https://toosim.com/esim-caribbean">Caribbean</a></li>
            <li><a href="https://toosim.com/esim-africa">Africa eSIMs</a></li>
          </ul>
        </div>

        <div class="col-lg-2 col-md-4 col-6">
          <h6>Useful Tools</h6>
          <ul>
            <li>
              <a href="https://toosim.com/esim-compatible">Supported Devices</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-4 col-6">
          <h6>FOLLOW US</h6>
          <ul>
            <li><a class="YouTube" href="#">YouTube</a></li>
            <li><a class="Instagram" href="#">Instagram</a></li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-4 col-6">
          <h6>Company</h6>
          <ul>
            <li><a href="https://toosim.com/about">About</a></li>
            <li><a href="https://toosim.com/about#contact">Contact</a></li>
            <li><a href="https://toosim.com/blog">Blog</a></li>
            <li>
              <a href="https://toosim.com/terms#privacy">Terms & Privacy</a>
            </li>
            <li>
              <a href="https://toosim.com/terms#terms-of-use"
                >Advertiser Disclosure</a
              >
            </li>
            <li><a href="https://toosim.com/sitemap">Sitemap</a></li>
          </ul>
        </div>
      </div>
      <div class="b-footer">
        <div class="copy">
          &copy; 2024 TooSim. All Rights Reserved ·
          <a href="#">support@toosim.com</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts"></script>

<style scoped></style>
