<template>
  <PopupTemplate>
    <p class="popup__title">
      Enter promo code{{ dataStore.showCashback ? " or rewards" : "" }}
    </p>
    <div
      style="
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: space-between;
      "
    >
      <input
        v-model="promoCode"
        type="text"
        class="form-control mt-3 mb-3"
        placeholder="Promo code"
      />
      <button
        class="btn btn-b btn-max btn-danger use-promo-button"
        :disabled="promoLoading || promoInvalid || !promoCode.trim().length"
        @click="checkPromo"
      >
        Use
        <MainLoader v-if="promoLoading" />
      </button>
    </div>
    <p v-if="promoInvalid" class="error-text mb-2">Promo code is invalid</p>
    <div v-if="dataStore.showCashback" class="cashback-info">
      <div class="or-row">
        <div class="or-line"></div>
        OR
        <div class="or-line"></div>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: space-between;
        "
      >
        <span style="font-size: 20px">
          Apply ${{ (maxUsedCashback / 100).toLocaleString("en-US") }} in
          rewards
        </span>
        <button
          class="btn btn-b btn-max btn-danger apply-rewards-button"
          @click="useCashback"
          :disabled="promoLoading"
        >
          Apply
        </button>
      </div>
      <div
        class="cashback-info__label"
        v-html="dataStore.minPaymentTermsText"
      ></div>
    </div>
  </PopupTemplate>
</template>

<script setup lang="ts">
import PopupTemplate from "@/components/popups/PopupTemplate.vue";
import {
  ref,
  Ref,
  defineEmits,
  watch,
  ComputedRef,
  computed,
  defineProps,
} from "vue";
import { useEsimStore } from "@/stores/esim";
import { useAppStore } from "@/stores/app";
import MainLoader from "@/components/MainLoader.vue";
import { useUserStore } from "@/stores/user";
import { useDataStore } from "@/stores/data";

const props = defineProps({ bundlePrice: Number });
defineEmits(["promo", "cashback"]);

const esimStore = useEsimStore();
const appStore = useAppStore();
const userStore = useUserStore();
const dataStore = useDataStore();

const promoCode: Ref<string> = ref("");
const promoInvalid: Ref<boolean> = ref(false);
const promoLoading: Ref<boolean> = ref(false);

const checkPromo = async () => {
  promoLoading.value = true;

  esimStore.activePromo = await esimStore.checkPromoIsValid(promoCode.value);
  promoInvalid.value = !esimStore.activePromo;

  promoLoading.value = false;

  if (esimStore.activePromo) {
    esimStore.useCashback = false;
    appStore.closePopup();
  }
};

const useCashback = () => {
  esimStore.useCashback = true;
  esimStore.activePromo = null;
  appStore.closePopup();
};

watch(promoCode, () => (promoInvalid.value = false));

const maxUsedCashback: ComputedRef<number> = computed(() => {
  const userCashback: number = userStore.cashback;

  const maxBundle: number = Math.round(
    (props.bundlePrice / 100) *
      (100 - dataStore.minTransactionPriceWithCashbackPercents)
  );

  if (maxBundle > userCashback) {
    return userCashback;
  }

  return maxBundle;
});
</script>

<style scoped lang="sass">
.or-row
  display: flex
  align-items: center
  gap: 10px
  margin: 20px 0
  font-size: 16px
  text-transform: uppercase

.or-line
  width: 100%
  flex: auto
  height: 1px
  background-color: #000000

.cashback-info
  display: flex
  flex-direction: column
  gap: 10px
</style>
