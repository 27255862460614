<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6468 5.58374C8.4246 5.58374 5.8125 8.19584 5.8125 11.418C5.8125 14.6402 8.4246 17.2523 11.6468 17.2523C14.869 17.2523 17.4811 14.6402 17.4811 11.418C17.4811 8.19584 14.869 5.58374 11.6468 5.58374ZM4.3125 11.418C4.3125 7.36741 7.59617 4.08374 11.6468 4.08374C15.6974 4.08374 18.9811 7.36741 18.9811 11.418C18.9811 13.1767 18.3621 14.7908 17.3301 16.0543L19.9117 18.6359C20.2045 18.9288 20.2045 19.4036 19.9117 19.6965C19.6188 19.9894 19.1439 19.9894 18.851 19.6965L16.268 17.1136C15.0067 18.1382 13.3985 18.7523 11.6468 18.7523C7.59617 18.7523 4.3125 15.4686 4.3125 11.418Z"
    />
  </svg>
</template>

<style scoped lang="sass">
svg
  fill: #B3B3B3
</style>
