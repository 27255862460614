<template>
  <PopupTemplate>
    <p class="popup__title">Privacy policy</p>
    <p class="privacy" v-html="dataStore.privacyText"></p>
  </PopupTemplate>
</template>

<script setup lang="ts">
import PopupTemplate from "@/components/popups/PopupTemplate.vue";
import { useDataStore } from "@/stores/data";

const dataStore = useDataStore();
</script>

<style scoped lang="sass">
.privacy
  height: 100%
  flex: auto
  overflow-y: auto
</style>
