<template>
  <div class="main-logo-wrapper">
    <svg
      class="main-logo"
      width="285"
      height="151"
      viewBox="0 0 285 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M172.636 5.70209C166.934 3.56353e-07 158.205 0 142.5 0C126.795 0 118.066 3.56353e-07 112.364 5.70209C106.662 11.4042 106.662 20.1329 106.662 35.838C106.662 51.543 106.662 60.2718 112.364 65.9739C115.058 68.668 118.432 70.0879 122.894 70.839L147.799 47.0217C149.772 45.1198 151.248 43.2814 152.234 41.5177C153.22 39.7502 153.714 37.7773 153.714 35.6026C153.714 32.7478 152.727 30.319 150.758 28.3124C148.785 26.3059 146.237 25.3044 143.109 25.3044C139.982 25.3044 137.467 26.3395 135.565 28.4133C133.663 30.4871 132.71 33.1215 132.71 36.3163H120.065C120.065 32.0341 121.033 28.1443 122.972 24.6431C124.912 21.1418 127.662 18.373 131.23 16.3328C134.799 14.2926 138.962 13.2725 143.722 13.2725C148.482 13.2725 152.387 14.2739 155.855 16.2805C159.322 18.2871 161.972 20.97 163.806 24.3367C165.641 27.6996 166.56 31.354 166.56 35.2962C166.56 43.1132 163.298 50.011 156.77 55.9934L140.232 71.6722C140.972 71.6722 141.727 71.6722 142.5 71.6722C158.205 71.6722 166.934 71.6722 172.636 65.9702C178.338 60.2681 178.338 51.5393 178.338 35.8343C178.338 20.1292 178.338 11.4004 172.636 5.69836V5.70209Z"
        fill="#552DEB"
      />
      <path
        d="M31.8473 137.236C29.3662 133.058 28.1256 128.518 28.1256 123.62C28.1256 118.721 29.3662 114.087 31.8473 109.91C34.3284 105.732 37.6727 102.433 41.8839 100.015C46.0913 97.5977 50.6799 96.387 55.6421 96.387C60.6044 96.387 65.1892 97.5977 69.4004 100.015C73.6078 102.433 76.9558 105.732 79.437 109.91C81.9181 114.087 83.1586 118.657 83.1586 123.62C83.1586 128.582 81.9181 133.058 79.437 137.236C76.9558 141.413 73.6116 144.728 69.4004 147.175C65.1929 149.626 60.6044 150.848 55.6421 150.848C50.6799 150.848 46.095 149.623 41.8839 147.175C37.6764 144.724 34.3284 141.41 31.8473 137.236ZM73.0249 133.798C74.8147 130.719 75.7115 127.326 75.7115 123.623C75.7115 119.92 74.8147 116.337 73.0249 113.258C71.2351 110.179 68.7988 107.776 65.7235 106.05C62.6446 104.324 59.2853 103.46 55.6421 103.46C51.9989 103.46 48.5425 104.324 45.4636 106.05C42.3846 107.776 39.952 110.179 38.1622 113.258C36.3723 116.337 35.4755 119.79 35.4755 123.623C35.4755 127.457 36.3873 130.723 38.207 133.798C40.0305 136.877 42.478 139.31 45.557 141.1C48.6359 142.889 51.9952 143.786 55.6384 143.786C59.2816 143.786 62.6446 142.889 65.7198 141.1C68.7988 139.31 71.2313 136.873 73.0212 133.798H73.0249Z"
        fill="#191919"
      />
      <path
        d="M90.4712 137.236C87.9901 133.058 86.7495 128.518 86.7495 123.62C86.7495 118.721 87.9901 114.087 90.4712 109.91C92.9523 105.732 96.2966 102.433 100.508 100.015C104.715 97.5977 109.304 96.387 114.266 96.387C119.228 96.387 123.813 97.5977 128.024 100.015C132.232 102.433 135.58 105.732 138.061 109.91C140.542 114.087 141.783 118.657 141.783 123.62C141.783 128.582 140.542 133.058 138.061 137.236C135.58 141.413 132.235 144.728 128.024 147.175C123.817 149.626 119.228 150.848 114.266 150.848C109.304 150.848 104.719 149.623 100.508 147.175C96.3003 144.724 92.9523 141.41 90.4712 137.236ZM131.649 133.798C133.439 130.719 134.335 127.326 134.335 123.623C134.335 119.92 133.439 116.337 131.649 113.258C129.859 110.179 127.426 107.776 124.347 106.05C121.269 104.324 117.909 103.46 114.266 103.46C110.623 103.46 107.166 104.324 104.087 106.05C101.008 107.776 98.576 110.179 96.7861 113.258C94.9963 116.337 94.0995 119.79 94.0995 123.623C94.0995 127.457 95.0112 130.723 96.831 133.798C98.6544 136.877 101.102 139.31 104.181 141.1C107.26 142.889 110.619 143.786 114.262 143.786C117.906 143.786 121.265 142.889 124.344 141.1C127.423 139.31 129.855 136.873 131.645 133.798H131.649Z"
        fill="#191919"
      />
      <path
        d="M144.933 143.08C143.329 140.726 142.53 138.163 142.53 135.401H149.88C149.88 137.725 150.978 139.721 153.179 141.383C155.376 143.046 158.112 143.88 161.377 143.88C164.643 143.88 167.251 143.08 169.385 141.477C171.522 139.874 172.587 137.882 172.587 135.495C172.587 133.548 172.053 131.96 170.984 130.738C169.916 129.512 168.567 128.586 166.934 127.958C165.301 127.33 163.134 126.702 160.432 126.075C154.842 124.692 150.616 122.872 147.757 120.608C144.899 118.347 143.471 115.141 143.471 110.997C143.471 108.232 144.29 105.721 145.923 103.46C147.556 101.2 149.753 99.4249 152.518 98.1358C155.283 96.8466 158.298 96.2039 161.564 96.2039C164.83 96.2039 167.846 96.8653 170.611 98.1844C173.376 99.5034 175.558 101.308 177.161 103.602C178.764 105.897 179.564 108.423 179.564 111.188H172.214C172.214 108.864 171.179 106.932 169.105 105.392C167.031 103.853 164.52 103.083 161.568 103.083C158.616 103.083 156.165 103.823 154.031 105.299C151.894 106.775 150.829 108.643 150.829 110.904C150.829 112.664 151.363 114.076 152.432 115.145C153.501 116.214 154.771 117.013 156.247 117.548C157.723 118.082 159.969 118.758 162.984 119.573C166.627 120.514 169.534 121.441 171.702 122.353C173.869 123.265 175.786 124.77 177.449 126.874C179.115 128.978 179.945 131.821 179.945 135.401C179.945 138.23 179.111 140.819 177.449 143.173C175.786 145.527 173.506 147.399 170.618 148.778C167.73 150.161 164.587 150.852 161.194 150.852C157.615 150.852 154.409 150.146 151.584 148.733C148.755 147.321 146.543 145.434 144.94 143.08H144.933Z"
        fill="#191919"
      />
      <path
        d="M185.109 86.2907C185.109 85.0352 185.565 83.9516 186.476 83.0398C187.388 82.1281 188.472 81.6722 189.727 81.6722C190.983 81.6722 192.066 82.1281 192.978 83.0398C193.89 83.9516 194.346 85.0352 194.346 86.2907C194.346 87.5462 193.89 88.6298 192.978 89.5416C192.066 90.4533 190.983 90.9092 189.727 90.9092C188.472 90.9092 187.388 90.4533 186.476 89.5416C185.565 88.6298 185.109 87.5462 185.109 86.2907ZM193.4 149.346H186.05V97.6127H193.4V149.346Z"
        fill="#191919"
      />
      <path
        d="M277.65 149.44V104.776H246.838V149.44H239.488V104.776H208.676V149.44H201.326V97.6127H285V149.44H277.65Z"
        fill="#191919"
      />
      <path
        d="M18.3281 149.44V104.682H29.2727V97.6127H0V104.682H10.9782V149.44H18.3281Z"
        fill="#191919"
      />
    </svg>
    <p class="main-logo__title">The Worldwide eSIM Store</p>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="sass">
.main-logo
  width: 100%
  height: auto

  &-wrapper
    display: flex
    flex-direction: column
    align-items: center
    text-align: center

  &__title
    font-size: 0.8em
</style>
