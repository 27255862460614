import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import ProfileView from "@/views/ProfileView.vue";
import EsimDetails from "@/views/EsimDetails.vue";
import SupportView from "@/views/SupportView.vue";
import SearchView from "@/views/SearchView.vue";
import BundleDetails from "@/views/BundleDetails.vue";
import { useUserStore } from "@/stores/user";
import { useAppStore } from "@/stores/app";
import PaymentView from "@/views/PaymentView.vue";
import { requestedCountriesFromUrl } from "@/helpers";
import PaymentDisabledView from "@/views/PaymentDisabledView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
    meta: {
      needAuth: true,
      title: "My profile",
      description: "My profile",
    },
  },
  {
    path: "/esim/:id",
    name: "esim",
    component: EsimDetails,
    props: true,
    meta: {
      needAuth: true,
    },
  },
  {
    path: "/support",
    name: "support",
    component: SupportView,
    meta: {
      title: "Customer support",
      description: "Customer support",
    },
  },
  {
    path: "/",
    name: "",
    component: SearchView,
    meta: {
      title:
        "Buy an eSIM online. local, regional and global plans. 150 + countries",
      description:
        "Toosim.com  offers more than 200 eSIMs packages. Purchase and activate eSIM within 5 minutes. Prices start from 1.55$ per GB. Start saving on data roaming now",
    },
  },
  {
    path: "/bundle/:id",
    name: "bundle",
    props: true,
    component: BundleDetails,
    meta: {
      needAuth: true,
    },
  },
  {
    path: "/payment/:id",
    name: "payment",
    component: PaymentView,
    props: true,
  },
  {
    path: "/payment-disabled",
    name: "payment-disabled",
    component: PaymentDisabledView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document?.getElementById("app")?.scrollIntoView({ behavior: "smooth" });
  },
});

export const affIdLocalKey = "affiliate_id";
export const affCreatedAtLocalKey = "affiliate_created_at";

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): Promise<void> => {
    // Get affiliate ID
    try {
      const affId = to.query?.aff_id;
      if (typeof affId === "string") {
        localStorage.setItem(affIdLocalKey, affId);
        localStorage.setItem(
          affCreatedAtLocalKey,
          new Date().getTime().toString()
        );
      }
    } catch (e) {
      console.log(e);
    }

    const appStore = useAppStore();
    const userStore = useUserStore();

    if (appStore.firstLoading) {
      await appStore.init();
    }

    const routeExist = routes.find((route) => route.name === to.name);
    if (!routeExist) {
      if (userStore.isAuth) {
        return next("/profile");
      } else {
        return next("/");
      }
    }

    if (to.meta.needAuth) {
      if (!userStore.isAuth) {
        if (to.name === "bundle" && to.params.id) {
          return next(
            `/?bundleId=${to.params.id}&requestedCountries=${JSON.stringify(
              requestedCountriesFromUrl().map((c) => c.iso)
            )}#auth`
          );
        } else {
          return next("/#auth");
        }
      }
    } else if (to.meta.withoutAuth) {
      if (userStore.isAuth) {
        return next("/profile");
      }
    }

    return next();
  }
);

router.beforeResolve((to, from) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  document.title =
    typeof to.meta.title === "string" ? `${to.meta.title} | Toosim` : "Toosim";
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      "content",
      typeof to.meta.description === "string"
        ? `${to.meta.description} | toosim.com`
        : "Buy travel esim online. Toosim Covers more than 200 destinations worldwide Cost from $1.99/GB | toosim.com"
    );

  if (to.name === "bundle") {
    const meta = document.createElement("meta");
    meta.name = "robots";
    meta.content = "noindex";
    document.getElementsByTagName("head")[0].appendChild(meta);
  } else {
    const metaTag = document
      .getElementsByTagName("head")[0]
      .querySelector('meta[name="robots"]');
    if (metaTag) {
      metaTag.remove();
    }
  }
});

export default router;
