<template>
  <div>
    <div v-if="esim" class="middle">
      <div class="container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/">eSIM Store</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link to="/profile">my eSIMs</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ esim.dataAmountGb() }}gb {{ esim.duration }} days
            </li>
          </ol>
        </nav>
      </div>
      <div class="description-tarif-group mb-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-8"
              :class="{ 'col-lg-12': esim.status() === EsimStatus.newest }"
            >
              <div class="description-tarif">
                <div class="description-tarif-head">
                  <div class="group-logo-tarif">
                    <figure class="logo-image">
                      <a href="#">
                        <img src="@/assets/images/logo-tarif.png" alt="" />
                      </a>
                    </figure>
                    <div class="data-tarif-item">
                      <div class="gb-info-tarif">
                        {{ esim.dataAmountGb() }} GB
                        <span>{{ esim.duration }} days</span>
                      </div>
                      <BundleCountries
                        :countries="esim.bundleCountries()"
                        :requested-countries="esim.requestedCountries()"
                        view-button
                      />
                    </div>
                  </div>
                  <div class="prise-tarif-sim-description">
                    <span
                      class="btn-new"
                      :class="{
                        active: esim?.status() === EsimStatus.active,
                        archive: esim?.status() === EsimStatus.archive,
                      }"
                    >
                      {{
                        esim.status() === EsimStatus.active
                          ? "Active"
                          : esim.status() === EsimStatus.archive
                          ? "Archive"
                          : "New"
                      }}
                    </span>
                    <span
                      v-if="
                        esim?.expireSoon() && esim?.type() !== EsimType.archive
                      "
                      class="exp error-text"
                    >
                      Exp. soon ({{ dateToString(esim.expiresAt()) }})
                    </span>
                    <span v-else-if="esim?.activatedAt">
                      Expired at {{ dateToString(esim.expiresAt()) }}
                    </span>
                  </div>
                </div>
                <div class="esim-custom-label">
                  <p class="esim-custom-label__title">Label:</p>
                  <input
                    v-model="esimLabelEditable"
                    class="esim-custom-label__value"
                    :class="{
                      placeholdered: esimLabelEditable === esimLabelPlaceholder,
                    }"
                    ref="esimCustomLabelInput"
                    placeholder="Add label"
                    @focus="
                      esimLabelEditable === esimLabelPlaceholder
                        ? (esimLabelEditable = '')
                        : null
                    "
                  />
                  <button
                    v-if="
                      (esimLabelEditable !== esim.customLabel &&
                        esimLabelEditable !== esimLabelPlaceholder) ||
                      esimLabelEditable === ''
                    "
                    class="esim-custom-label__cancel"
                    @click="getLabelFromEsim"
                  >
                    Cancel
                  </button>
                  <button
                    v-if="
                      (esimLabelEditable !== esim.customLabel &&
                        esimLabelEditable !== esimLabelPlaceholder) ||
                      esimLabelEditable === ''
                    "
                    class="esim-custom-label__save"
                    @click="saveLabel"
                  >
                    Save
                  </button>
                  <button
                    v-else
                    class="esim-custom-label__edit"
                    @click="() => esimCustomLabelInput.focus()"
                  >
                    <PenIcon class="esim-custom-label__icon" />
                  </button>
                </div>
                <div
                  v-if="esim.status() === EsimStatus.active"
                  class="description-usage"
                >
                  <span class="usage-text mb-0">
                    <span>Usage</span>
                  </span>
                  <MainLoader
                    v-if="esimLoading"
                    class="mb-2"
                    primary
                    style="height: 20px"
                  />
                  <span v-else class="usage-text">
                    {{ esim.usedDataMb() }} mb / {{ esim.dataAmount }} mb
                  </span>
                  <div class="sale-group">
                    <span
                      :style="`transition: 0.3s; width: ${
                        esimLoading
                          ? 0
                          : Math.round(
                              (esim.usedDataMb() / esim.dataAmount) * 100
                            )
                      }%`"
                    ></span>
                  </div>
                </div>
                <EsimActivation
                  v-if="esim.status() === EsimStatus.newest"
                  :esim="esim"
                />
                <div
                  v-if="esim.status() !== EsimStatus.newest"
                  class="description-tarif-middle"
                >
                  <!-- <div v-show="false"  class="description-tarif-item">
                    <ul class="list-tarif">
                      <li>Plan Type</li>
                      <li>{{ esim.planType }}</li>
                    </ul>
                  </div> -->
                </div>
                <div
                  v-if="esim.status() !== EsimStatus.newest"
                  class="description-tarif-content"
                >
                  <div
                    class="description-tarif-content-item"
                    :class="{ active: detailsOpened }"
                  >
                    <h6 @click="detailsOpened = !detailsOpened">
                      eSIM details
                    </h6>
                    <div class="tarif-content">
                      <div class="activate-sim-manual__item">
                        <div class="pin-code">
                          <span>ICCID</span> {{ esim.iccid }}
                        </div>
                        <button
                          class="copy-btn"
                          @click="copyToClipBoard(esim?.iccid || '')"
                        >
                          Copy
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="description-tarif-content-item"
                    :class="{ active: termsOpened }"
                  >
                    <h6 @click="termsOpened = !termsOpened">
                      Terms & Conditions
                    </h6>
                    <div class="tarif-content">
                      <p v-html="dataStore.termsText"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="esim.status() !== EsimStatus.newest" class="col-lg-4">
              <aside class="sidebar">
                <div class="by-moore-data">
                  <h5>Buy Another eSIM</h5>
                  <ul>
                    <BundleSmallCard
                      v-for="b in esim.otherBundles"
                      :key="b.id"
                      :bundle="b"
                      :requested-countries="esim.requestedCountries()"
                    />
                  </ul>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="esimLoading" class="middle">
      <MainLoader primary />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  ComputedRef,
  defineProps,
  onMounted,
  ref,
  Ref,
  watch,
} from "vue";
import { Esim, EsimStatus, EsimType, useEsimStore } from "@/stores/esim";
import MainLoader from "@/components/MainLoader.vue";
import BundleCountries from "@/components/bundle/BundleCountries.vue";
import { Country, useDataStore } from "@/stores/data";
import { copyToClipBoard, dateToString } from "@/helpers";
import BundleSmallCard from "@/components/bundle/BundleSmallCard.vue";
import EsimActivation from "@/components/esim/EsimActivation.vue";
import PenIcon from "@/components/icons/PenIcon.vue";
import router from "@/router";

const esimStore = useEsimStore();
const dataStore = useDataStore();

const props = defineProps({
  id: String,
});
const esimId: ComputedRef<number> = computed(
  () => parseInt(props.id || "") || 0
);

const esim: ComputedRef<Esim | undefined> = computed(() =>
  esimStore.esims.find((esim: Esim) => esim.id === esimId.value)
);
const esimLoading: Ref<boolean> = ref(false);
const detailsOpened: Ref<boolean> = ref(false);
const termsOpened: Ref<boolean> = ref(false);
const esimLabelEditable: Ref<string> = ref("");
const esimCustomLabelInput = ref<HTMLInputElement>();

const esimLabel: ComputedRef<string | undefined> = computed(() =>
  esim.value ? esim.value.customLabel : undefined
);

async function getEsim(): Promise<void> {
  esimLoading.value = true;
  getLabelFromEsim();
  const response = await esimStore.getEsim(esimId.value);
  if (!response) {
    await router.push("/profile");
    return;
  }
  changeMeta();
  esimLoading.value = false;
  getLabelFromEsim();
}

const esimLabelPlaceholder = "Name your eSim";

function getLabelFromEsim(): void {
  esimLabelEditable.value =
    esimLabel.value && esimLabel.value.length
      ? esimLabel.value
      : esimLabelPlaceholder;
}

function saveLabel(): void {
  if (!esim.value) return;

  esimStore.setEsimLabel(esim.value.id, esimLabelEditable.value);
  esim.value.customLabel = esimLabelEditable.value;
}

watch(esimLabel, getLabelFromEsim);

onMounted(() => {
  getEsim();
});

function changeMeta(): void {
  const text = `eSIM: ${
    esim.value?.duration
  } days, ${esim.value?.dataAmountGb()} GB, ${esim.value
    ?.requestedCountries()
    .map((c) => c.name)
    .join(", ")} | Toosim`;
  document.title = text;
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", text);
}
</script>

<style scoped lang="sass">
.esim-custom-label
  display: flex
  align-items: center
  gap: 10px
  padding: 20px 30px
  border-top: 1px solid #F2F2F2

  @media (max-width: 1199px)
    padding: 20px

  &__title
    font-weight: bold

  &__value
    width: 100%
    flex: auto
    padding: 0
    border: none
    margin: 0
    outline: none

    &.placeholdered
      opacity: 0.3

  &__edit
    padding: 0
    margin: 0
    border: none
    outline: none
    background: none
    cursor: pointer

  &__icon
    width: 25px
    height: auto
    fill: rgb(85, 45, 235)

  &__cancel,
  &__save
    padding: 5px 10px
    outline: none
    border: none
    cursor: pointer
    border-radius: 5px

  &__cancel
    background-color: rgb(227, 227, 227)
    color: rgb(85, 45, 235)

  &__save
    background-color: rgb(85, 45, 235)
    color: #fff
</style>
