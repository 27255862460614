<template>
  <div>
    <h6 class="open-instruction">Instruction for eSIM activation</h6>
    <div class="button-toggle-group" style="margin-left: 30px">
      <button
        class="button-toggle"
        :class="{ active: currentInstruction === InstructionType.Ios }"
        @click="currentInstruction = InstructionType.Ios"
      >
        iOS
      </button>
      <button
        class="button-toggle"
        :class="{ active: currentInstruction === InstructionType.Android }"
        @click="currentInstruction = InstructionType.Android"
      >
        Android
      </button>
    </div>
    <ActivationIos
      v-if="currentInstruction === InstructionType.Ios"
      :esim="esim"
    />
    <ActivationAndroid
      v-if="currentInstruction === InstructionType.Android"
      :esim="esim"
    />
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, Ref } from "vue";
import { Esim } from "@/stores/esim";
import ActivationIos from "@/components/esim/activation/ActivationIos.vue";
import ActivationAndroid from "@/components/esim/activation/ActivationAndroid.vue";

enum InstructionType {
  Ios,
  Android,
}

defineProps({
  esim: Esim,
});

const currentInstruction: Ref<InstructionType> = ref(InstructionType.Ios);
</script>
