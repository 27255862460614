<template>
  <div class="popup-wrapper">
    <div class="popup" :style="`width: ${width}px;`">
      <button class="popup__close" @click="appStore.closePopup">
        <CloseIcon class="popup__close--icon" />
      </button>
      <slot />
    </div>
    <div class="popup__overlay" @click="appStore.closePopup"></div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import { useAppStore } from "@/stores/app";
import CloseIcon from "@/components/icons/CloseIcon.vue";

const appStore = useAppStore();

defineProps({
  width: { type: Number, default: 630 },
});
</script>

<style scoped lang="sass">
@import "@/assets/sass/variables"

.popup
  max-width: calc(100% - 40px)
  max-height: calc(100% - 80px)
  background-color: $backgroundColor
  padding: 16px
  position: relative
  display: flex
  flex-direction: column
  overflow-y: auto

  @media (max-width: 600px)
    width: 100%
    max-width: 100%
    border-radius: 16px 16px 0 0
    height: calc(100% - 80px)

  &__close
    position: absolute
    top: 16px
    right: 16px
    width: 24px
    background: none
    padding: 0
    border: none
    outline: none
    cursor: pointer

    &--icon
      width: 100%
      height: auto

  &-wrapper
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    z-index: 9999

    @media (max-width: 600px)
      justify-content: flex-end

  &__overlay
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: -1
    cursor: pointer
    background-color: rgba(0,0,0,0.5)
    backdrop-filter: blur(10px)
</style>
