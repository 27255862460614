<template>
  <div class="search-duration">
    <div class="days-row">
      <div class="days">
        <button
          v-for="day in days"
          :key="day"
          class="day-button"
          :class="{ active: selectedDay === day }"
          @click="selectedDay = day"
          :data-duration="day"
        >
          {{ day }} days
        </button>
      </div>
      <div class="days-select-wrapper">
        <div class="input-group">
          <p
            v-if="!selectedDay || days.includes(selectedDay)"
            class="form-control"
          >
            I need more days
          </p>
          <p v-else class="form-control">
            {{ selectedDay === 360 ? "Unlimited" : `${selectedDay} days` }}
          </p>
          <SelectIcon class="input-suffix" />
        </div>
        <select class="days-select" v-model="selectedDay">
          <option
            v-for="day in allDays"
            :key="day"
            :value="day"
            :data-duration="day"
          >
            {{ day === 360 ? "Unlimited" : `${day} days` }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, Ref, defineModel } from "vue";
import SelectIcon from "@/components/icons/SelectIcon.vue";

const selectedDay = defineModel({
  type: Number,
});

const days: Ref<number[]> = ref([7, 15, 30]);
const allDays: Ref<number[]> = ref([90, 180, 360]);
</script>

<style scoped lang="sass">
@import "@/assets/sass/variables"

.search-duration
  width: 100%

.days
  display: flex
  align-items: stretch
  gap: 16px
  overflow-x: auto
  flex: auto

  @media(max-width: 600px)
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 8px

  &-row
    display: flex
    align-items: stretch
    gap: 16px

    @media (max-width: 600px)
      flex-direction: column

  &-select
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    opacity: 0
    z-index: 3
    cursor: pointer

    &-wrapper
      position: relative
      width: 335px
      max-width: 100%

      @media (max-width: 600px)
        width: 100%

        .form-control
          text-align: center

      & .input-group
        margin: 0

.day-button
  font-size: 16px
  font-weight: 500
  padding: 0 16px
  height: 48px
  border: 2px solid $secondaryContainerColor
  background-color: $secondaryContainerColor
  cursor: pointer
  border-radius: 6px
  transition: 0.3s
  min-width: 94px

  &.active
    border-color: $primaryColor
    color: $primaryColor
</style>
