<template>
  <div class="esim-error-payment">
    <SuccessIcon class="success-payment__icon" />
    <p class="success-payment__title">Thank you</p>
    <p class="success-payment__description">The payment was successful</p>
    <p
      v-if="dataStore.cashbackEnabled && dataStore.cashbackMaxPercent"
      class="mt-5"
      style="
        display: flex;
        align-items: center;
        color: #330fbd;
        font-size: 20px;
        gap: 10px;
      "
    >
      You just earned ${{
        (
          Math.round(transaction.amount * dataStore.cashbackMaxPercent) / 100
        ).toLocaleString("en-US")
      }}
      in rewards
      <button
        class="info-button"
        @click="appStore.showPopup({ component: PopupCashbackInfo })"
      >
        <InfoIcon class="info-button__icon" />
      </button>
    </p>
    <div v-if="!userStore.promoSubscribed" class="checkbox-group mt-3">
      <input type="checkbox" id="check" v-model="promoSubscriptioned" />
      <label for="check">
        <span>
          Inform me about new promotions and tips.
          <button
            class="button-link"
            @click.prevent="appStore.showPopup({ component: PopupPromoTerms })"
          >
            Read more
          </button>
        </span>
      </label>
    </div>
    <button
      class="btn btn-b btn-max btn-danger success-payment__button mt-4"
      @click="goEsims"
    >
      Show my ESIMs
    </button>
  </div>
</template>

<script setup lang="ts">
import SuccessIcon from "@/components/icons/SuccessIcon.vue";
import { useUserStore } from "@/stores/user";
import router from "@/router";
import { ref, Ref, defineProps, onBeforeUnmount } from "vue";
import { useAppStore } from "@/stores/app";
import { useDataStore } from "@/stores/data";
import PopupPromoTerms from "@/components/popups/PopupPromoTerms.vue";
import { Transaction } from "@/stores/esim";
import InfoIcon from "@/components/icons/InfoIcon.vue";
import PopupCashbackInfo from "@/components/popups/PopupCashbackInfo.vue";

defineProps({
  transaction: Transaction,
});

const userStore = useUserStore();
const appStore = useAppStore();
const dataStore = useDataStore();

const promoSubscriptioned: Ref<boolean> = ref(false);

const goEsims = () => {
  if (!userStore.isAuth) {
    router.push({ name: `${router.currentRoute.value.name}#auth` });
  } else {
    router.push("/profile");
  }
};

onBeforeUnmount(() => {
  if (promoSubscriptioned.value && !userStore.promoSubscribed) {
    userStore.promoSubscribed = true;
  }
});
</script>

<style scoped lang="sass">
.esim-error-payment
  display: flex
  flex-direction: column
  align-items: center

  &__icon
    width: 70px
    height: auto
    margin-bottom: 16px

  &__title
    font-size: 24px
    padding: 0
    margin-bottom: 12px
    font-weight: bold
    text-align: center

  &__description
    font-size: 16px
    color: #828282
    padding: 0
    margin-bottom: 22px
    text-align: center
</style>
