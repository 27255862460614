<template>
  <div>
    <div class="search-country-row">
      <div class="input-group">
        <input
          v-model="searchCountriesText"
          type="text"
          class="form-control active"
          placeholder="Search a country"
          @focus="searchInputClick"
        />
        <SearchIcon class="input-suffix" />
      </div>
      <div
        v-if="!isMobile || requestedCountries?.length"
        class="search-country-selected"
      >
        <button
          v-for="country in requestedCountries"
          :key="country.id"
          class="search-country-selected__button"
          :data-iso="country.iso"
          @click="() => countryClick(country)"
        >
          {{ country.name }}
          <CloseIcon class="search-country-selected__button--suffix" />
        </button>
      </div>
    </div>
    <div class="search-country__options">
      <button
        v-for="country in searchFilteredCountries"
        :key="country.id"
        class="search-country__button"
        :class="{ active: requestedCountries?.includes(country) }"
        @click="() => countryClick(country)"
        :data-iso="country.iso"
      >
        <CountryIcon class="search-country__button--icon" :country="country" />
        <span>{{ country.name }}</span>
        <CheckIcon
          v-if="requestedCountries?.includes(country)"
          class="search-country__button--suffix"
        />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import SearchIcon from "@/components/icons/SearchIcon.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import CheckIcon from "@/components/icons/CheckIcon.vue";
import { Country, useDataStore } from "@/stores/data";
import { computed, ComputedRef, ref, Ref, defineModel, PropType } from "vue";
import { detectMobile, getCountryImageSrc } from "@/helpers";
import CountryIcon from "@/components/icons/CountryIcon.vue";

const dataStore = useDataStore();

const requestedCountries = defineModel({
  type: Array as PropType<Array<Country>>,
});
const isMobile: boolean = detectMobile();
const searchCountriesText: Ref<string> = ref("");
const searchFilteredCountries: ComputedRef<Country[]> = computed(() => {
  return searchCountriesText.value.trim().length
    ? [...dataStore.countries].filter(
        (c: Country) =>
          c.name
            .trim()
            .toLowerCase()
            .includes(searchCountriesText.value.trim().toLowerCase()) ||
          c.alias
            .split(",")
            .map((a) => a.trim().toLowerCase())
            .filter((a) =>
              a
                .toLowerCase()
                .trim()
                .includes(searchCountriesText.value.trim().toLowerCase())
            ).length
      )
    : dataStore.countries;
});

function searchInputClick(): void {
  searchCountriesText.value = "";
}

function countryClick(country: Country): void {
  const countryExist: number | undefined = requestedCountries.value?.findIndex(
    (c: Country) => c.iso === country.iso
  );

  if (countryExist >= 0) {
    requestedCountries.value?.splice(countryExist, 1);
  } else {
    requestedCountries.value?.push(country);
  }
}
</script>

<style scoped lang="sass">
@import "@/assets/sass/variables"

.search
  &-country
    &-row
      display: flex
      align-items: stretch
      gap: 15px

      @media (max-width: 600px)
        flex-direction: column

      & .input-group
        margin: 0

    &-selected
      padding: 0 15px
      border: 2px solid $borderColor
      border-radius: 6px
      display: flex
      align-items: center
      gap: 16px
      overflow-x: auto
      width: 65%
      min-width: 65%
      height: 60px

      @media (max-width: 600px)
        padding: 0
        border: none
        height: auto
        width: 100%

      &__button
        font-size: 16px
        padding: 0 10px
        border: 1px solid $primaryColor
        outline: none
        cursor: pointer
        background-color: $secondaryContainerColor
        border-radius: 6px
        height: 36px
        display: flex
        align-items: center
        gap: 4px
        white-space: nowrap

        &--suffix
          width: 24px
          min-width: 24px
          height: auto

    &__options
      display: grid
      grid-template-columns: repeat(4, 1fr)
      grid-gap: 16px
      padding: 24px
      height: 100px
      overflow-y: auto
      border: 2px solid $borderColor
      border-radius: 6px
      margin-top: 15px
      margin-bottom: 45px

      @media (max-width: 600px)
        grid-template-columns: 1fr
        padding: 0
        border: none
        border-radius: 0
        height: initial
        max-height: 200px
        overflow-y: auto

    &__button
      display: flex
      align-items: center
      gap: 12px
      border: 1px solid $secondaryContainerColor
      background-color: $secondaryContainerColor
      cursor: pointer
      outline: none
      border-radius: 6px
      padding: 0 17px
      font-size: 16px
      font-weight: 600
      white-space: nowrap
      width: 100%
      height: 56px
      transition: 0.3s

      &.active
        border-color: $primaryColor

      span
        width: 0
        text-align: left
        text-overflow: ellipsis
        flex: auto
        overflow: hidden

      &--icon
        width: 32px !important
        min-width: 32px !important
        height: 21px !important
        object-fit: contain

      &--suffix
        width: 24px
        min-width: 24px
        height: auto
        margin-left: auto
</style>
