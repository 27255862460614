<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9102 12.6687C14.3831 12.6687 16.3878 10.6639 16.3878 8.19102C16.3878 5.71809 14.3831 3.71338 11.9102 3.71338C9.43723 3.71338 7.43252 5.71809 7.43252 8.19102C7.43252 10.6639 9.43723 12.6687 11.9102 12.6687ZM8.59327 13.0932C9.08773 12.8946 9.63624 12.9813 10.1381 13.1603C10.692 13.3578 11.2885 13.4654 11.9102 13.4654C12.5318 13.4654 13.1284 13.3578 13.6822 13.1603C14.1841 12.9813 14.7326 12.8946 15.2271 13.0932C17.291 13.922 18.9728 15.5013 19.9346 17.4934C20.8952 19.4828 19.0251 21.3608 16.8159 21.3608H7.00441C4.79527 21.3608 2.92514 19.4828 3.88568 17.4934C4.84751 15.5013 6.52929 13.922 8.59327 13.0932Z"
      fill="#552DEB"
    />
  </svg>
</template>
