<template>
  <PopupTemplate>
    <p class="popup__title">{{ dataStore.cashbackInfo.title }}</p>
    <p class="popup__description" v-html="dataStore.cashbackInfo.text"></p>
  </PopupTemplate>
</template>

<script setup lang="ts">
import PopupTemplate from "@/components/popups/PopupTemplate.vue";
import { useDataStore } from "@/stores/data";

const dataStore = useDataStore();
</script>
