<template>
  <PopupTemplate :width="400">
    <div class="login">
      <MainLogo class="login__logo" />
      <AuthCode v-if="codeSent" @back="codeSent = false" />
      <AuthEmail v-else @code-sent="codeSent = true" />
    </div>
  </PopupTemplate>
</template>

<script setup lang="ts">
import MainLogo from "@/components/MainLogo.vue";
import AuthEmail from "@/components/auth/AuthEmail.vue";
import AuthCode from "@/components/auth/AuthCode.vue";
import { ref, Ref } from "vue";
import PopupTemplate from "@/components/popups/PopupTemplate.vue";

const codeSent: Ref<boolean> = ref(false);
</script>

<style lang="sass" scoped>
.login
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  margin: 0
  max-width: 100%

  &__logo
    width: 170px
    height: auto
    max-width: calc(100% - 20px)
    margin-bottom: 20px

  > *:not(.login__logo)
    width: 100%
</style>
