import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia, Pinia } from "pinia";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import vClickOutside from "click-outside-vue3";
import { gtm } from "@/gtm";

const pinia: Pinia = createPinia();

const app = createApp(App);

app.use(pinia).use(router).use(vClickOutside).use(gtm);

await router.isReady();

app.mount("#app");
