import { createGtm, VueGtmPlugin } from "@gtm-support/vue-gtm";
import router from "@/router";

export const gtm: VueGtmPlugin = createGtm({
  id: "GTM-NSN67TVM",
  defer: false,
  compatibility: false,
  enabled: true,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
});
