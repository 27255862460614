<script setup lang="ts">
import { computed, ComputedRef, defineProps } from "vue";
import { Country } from "@/stores/data";

const props = defineProps({
  country: { type: Country, required: true },
});

const countryImageSrc: ComputedRef<string | undefined> = computed(() => {
  if (props.country?.isRegion) {
    return require(`@/assets/images/countries/unknown.svg`);
  }

  try {
    return require(`@/assets/images/countries/${props.country?.iso.toLowerCase()}.svg`);
  } catch (e) {
    return undefined;
  }
});
</script>

<template>
  <div v-if="!countryImageSrc" class="country-icon iso">
    {{ country.iso.toUpperCase() }}
  </div>
  <img v-else class="country-icon" :src="countryImageSrc" :alt="country.iso" />
</template>

<style lang="sass" scoped>
.country-icon
  width: 40px
  height: 24px
  object-fit: contain

  &.iso
    border-radius: 5px
    background-color: #f2f2f2
    font-size: 10px
    text-align: center
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
</style>
