<template>
  <div class="middle payment">
    <SuccessPayment
      v-if="transaction?.status === TransactionStatus.payed"
      :transaction="transaction"
    />
    <EsimErrorPayment
      v-else-if="transaction?.status === TransactionStatus.payedWithSimFail"
    />
    <FailPayment v-else-if="transaction?.status === TransactionStatus.failed" />
    <WaitPayment v-else />
  </div>
</template>

<style lang="sass" scoped>
.payment
    display: flex
    flex-direction: column
    align-items: center
    padding: 30px
</style>

<script setup lang="ts">
import { defineProps, onMounted, ref, Ref } from "vue";
import SuccessPayment from "@/components/payment/SuccessPayment.vue";
import { Transaction, TransactionStatus, useEsimStore } from "@/stores/esim";
import EsimErrorPayment from "@/components/payment/EsimErrorPayment.vue";
import FailPayment from "@/components/payment/FailPayment.vue";
import WaitPayment from "@/components/payment/WaitPayment.vue";
import router from "@/router";
import { useGtm } from "@gtm-support/vue-gtm";

const props = defineProps({
  id: String,
});

const checkInterval: Ref<number> = ref(0);
const esimStore = useEsimStore();
const transaction: Ref<Transaction | undefined> = ref();

async function checkTransaction(): Promise<void> {
  const transactionId: number | undefined = parseInt(String(props.id));
  if (!transactionId) return;

  transaction.value = await esimStore.getTransaction(transactionId);
  if (!transaction.value) {
    stopCheckInterval();
    await router.push("/");
    return;
  }

  if (
    transaction.value &&
    transaction.value.status === TransactionStatus.payed
  ) {
    try {
      // eslint-disable-next-line no-undef
      gtag("event", "purchase", {
        transaction_id: transaction.value.id,
        value: transaction.value.amount / 100,
        tax: transaction.value.tax,
        currency: "USD",
        coupon: "SUMMER_SALE",
        items: [
          {
            item_id: transaction.value.bundleSubId,
            item_name: transaction.value.productName,
            price: transaction.value.amount / 100,
            quantity: 1,
          },
        ],
      });

      // eslint-disable-next-line no-undef
      fbq("track", "Purchase", {
        value: transaction.value.amount / 100,
        currency: "USD",
        contents: [
          {
            id: transaction.value.bundleSubId,
            quantity: 1,
          },
        ],
        content_name: transaction.value.productName,
      });
    } catch (e) {
      console.log(e);
    }
  }

  if (transaction.value.status !== TransactionStatus.created) {
    stopCheckInterval();
    return;
  }
}

function startCheckInterval(): void {
  stopCheckInterval();
  checkInterval.value = setInterval(checkTransaction, 5000);
}

function stopCheckInterval(): void {
  clearInterval(checkInterval.value);
  checkInterval.value = 0;
}

onMounted(() => {
  checkTransaction();
  startCheckInterval();
});
</script>
