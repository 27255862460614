<template>
  <header class="header header-main" role="banner">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-xl-2 col-lg-3 col-sm-4 col-5">
          <router-link class="logo" to="https://toosim.com"></router-link>
        </div>
        <div class="col-xl-6 col-lg-5">
          <div class="group-dropdown">
            <ul class="dropdown-main">
              <li>
                <a href="https://toosim.com/#how-it-works">TooSim App</a>
              </li>
              <li>
                <a href="https://toosim.com/esim-compatible">Compatibility</a>
              </li>
              <li><a href="https://toosim.com/esim-price">Price</a></li>
              <li><a href="https://toosim.com/blog">Blog</a></li>
              <li>
                <a href="#" class="btn btn-b btn-danger">
                  <i class="icon app-d"></i>Buy an eSIM
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-sm-8 col-7 flex-nowrap">
          <button class="btn btn-b btn-danger header__buy" @click="goSearch">
            <i class="icon app-d"></i>Buy an eSIM
          </button>
          <div v-if="userStore.isAuth" class="header__user-wrapper">
            <div
              v-if="dataStore.cashbackEnabled && userStore.cashback > 0"
              class="header__cashback"
            >
              <CoinIcon class="header__cashback-icon" />
              ${{ (userStore.cashback / 100).toLocaleString("en-US") }}
            </div>
            <button
              class="btn btn-b btn-default btn-primary align-items-center justify-content-center header__user"
              @click="openProfileMenu"
              style="
                height: 48px;
                width: 48px;
                min-width: 48px;
                margin-left: 10px;
              "
            >
              <ProfileIcon style="min-width: 24px" />
            </button>
            <Transition name="fade">
              <div
                v-click-outside="closeProfileMenu"
                v-if="menuOpened"
                class="header__user-menu"
              >
                <button class="header__user-menu--item disabled">
                  {{ userEmailTrimmed }}
                </button>
                <button
                  class="header__user-menu--item"
                  :class="{ active: currentRoutePath === '/profile' }"
                  @click="goProfile"
                >
                  Show my eSIM
                </button>
                <button
                  class="header__user-menu--item"
                  :class="{ active: currentRoutePath === '/support' }"
                  @click="goSupport"
                >
                  Support
                </button>
                <button class="header__user-menu--item" @click="logout">
                  Log out
                </button>
              </div>
            </Transition>
          </div>
          <button
            v-else
            class="btn btn-b btn-default header__user"
            @click="goAuth"
            style="margin-left: 10px"
          >
            Log in
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/user";
import ProfileIcon from "@/components/icons/ProfileIcon.vue";
import { detectMobile } from "@/helpers";
import { computed, ComputedRef, ref, Ref } from "vue";
import router from "@/router";
import { useBundleStore } from "@/stores/bundle";
import { useAppStore } from "@/stores/app";
import PopupAuth from "@/components/popups/PopupAuth.vue";
import CoinIcon from "@/components/icons/CoinIcon.vue";
import { useDataStore } from "@/stores/data";

const userStore = useUserStore();
const appStore = useAppStore();
const bundleStore = useBundleStore();
const dataStore = useDataStore();
const isMobile: boolean = detectMobile();

const menuOpened: Ref<boolean> = ref(false);

const openProfileMenu = (): void => {
  menuOpened.value = true;
};
const closeProfileMenu = (): void => {
  menuOpened.value = false;
};
const goProfile = (): void => {
  closeProfileMenu();
  router.push("/profile");
};
const goSupport = (): void => {
  closeProfileMenu();
  router.push("/support");
};
const logout = (): void => {
  closeProfileMenu();
  userStore.logout();
};

const goSearch = (): void => {
  bundleStore.clearBundles();
  router.push("/");
};

const goAuth = () => {
  router.push(`/${router.currentRoute.value.name}#auth`);
};

const currentRoutePath: ComputedRef<string> = computed(
  () => router.currentRoute.value.path
);
const userEmailTrimmed: ComputedRef<string> = computed(
  () => userStore.email.split("@")[0]
);
</script>

<style scoped lang="sass">
@import '@/assets/sass/variables'

.header
  &__cashback
    margin-left: 10px
    background-color: #F2F2F2
    border-radius: 6px
    display: flex
    align-items: center
    justify-content: center
    padding: 5px
    min-width: 100px
    gap: 10px

    &-icon
      height: 20px
      width: auto

  &__buy
    @media (max-width: 600px)
      display: none !important

  &__user
    flex: auto
    max-width: 270px
    overflow: hidden

    &-wrapper
      position: relative
      display: flex
      align-items: stretch

    &-menu
      position: absolute
      top: 100%
      right: 0
      max-width: 200px
      display: flex
      flex-direction: column
      background-color: $backgroundColor
      border: 1px solid $borderColor
      border-radius: 6px
      overflow: hidden

      &--item
        padding: 12px 24px
        border-radius: 0
        background: none
        outline: none
        border-bottom: solid $borderColor
        border-width: 0 0 1px 0
        text-align: left
        cursor: pointer
        font-size: 16px
        font-weight: 500
        color: $onBackgroundColor
        text-overflow: ellipsis
        overflow: hidden

        &.active
          color: $primaryColor

        &.disabled
          cursor: initial
          color: rgba(130, 130, 130, 1)

        &:last-child
          border: none
</style>
