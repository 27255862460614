<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.7178 7.53255C19.0107 7.82545 19.0107 8.30032 18.7178 8.59321L10.8428 16.4679C10.5499 16.7607 10.0751 16.7607 9.78219 16.4679L5.84469 12.5307C5.55179 12.2378 5.55177 11.763 5.84465 11.4701C6.13753 11.1772 6.6124 11.1771 6.90531 11.47L10.3125 14.8769L17.6572 7.53252C17.9501 7.23964 18.425 7.23965 18.7178 7.53255Z"
      fill="#552DEB"
    />
  </svg>
</template>
