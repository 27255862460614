<template>
  <svg
    width="69"
    height="68"
    viewBox="0 0 69 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="34.5" cy="34" r="32.5" stroke="#552DEB" stroke-width="3" />
    <path
      d="M25.5 32.8947L33.2 41L46.5 27"
      stroke="#552DEB"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
