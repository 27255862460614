<template>
  <div class="group-activate-sim-middle">
    <div class="activate-sim-middle-item">
      <div class="group-instruction">
        <p class="activate-sim-instruction__title">
          Scan QR code for activation
        </p>
        <p class="list-open-instruction__warning error-text">
          Warning! eSIM can be installed only once. If you remove eSIM from your
          phone you can't reinstall it again.
        </p>
        <ul class="list-open-instruction">
          <li class="li-kode">
            <div class="group-pin-puk">
              <ActivationQr :esim="esim" />
              <div
                style="padding: 0 20px; word-break: break-word"
                v-if="dataStore.activationInstructions"
                v-html="
                  dataStore.activationInstructions.android.tutorial_html_text
                "
              ></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="group-activate-sim-right">
      <div
        class="description-tarif-content-item"
        :class="{ active: manualOpened }"
      >
        <h6 @click="manualOpened = !manualOpened">Manual eSIM activation</h6>
        <div class="tarif-content">
          <div class="activate-sim-manual__item">
            <div class="pin-code">
              <span>LPA</span>
              {{ `LPA:1$${esim.smdpAddress}$${esim.matchingId}` }}
            </div>
            <button
              class="copy-btn"
              @click="
                copyToClipBoard(
                  `LPA:1$${esim?.smdpAddress}$${esim?.matchingId}`
                )
              "
            >
              Copy
            </button>
          </div>
        </div>
      </div>
      <div
        class="description-tarif-content-item"
        :class="{ active: instructionOpened }"
      >
        <h6 @click="instructionOpened = !instructionOpened">
          See activation tutorial
        </h6>
        <div class="tarif-content">
          <div class="video-group v1">
            <div class="video">
              <iframe
                :src="dataStore.activationInstructions.android.videoguide_link"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div
        class="description-tarif-content-item"
        :class="{ active: detailsOpened }"
      >
        <h6 @click="detailsOpened = !detailsOpened">eSIM details</h6>
        <div class="tarif-content">
          <div class="activate-sim-manual__item">
            <div class="pin-code"><span>ICCID</span> {{ esim.iccid }}</div>
            <button
              class="copy-btn"
              @click="copyToClipBoard(esim?.iccid || '')"
            >
              Copy
            </button>
          </div>
        </div>
      </div>
      <div
        class="description-tarif-content-item"
        :class="{ active: termsOpened }"
      >
        <h6 @click="termsOpened = !termsOpened">Terms & Conditions</h6>
        <div class="tarif-content">
          <p v-html="dataStore.termsText"></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { defineProps, ref, Ref } from "vue";
import QRCodeVue3 from "qrcode-vue3";
import { Esim } from "@/stores/esim";
import { copyToClipBoard } from "@/helpers";
import ActivationQr from "@/components/esim/activation/ActivationQr.vue";
import { useDataStore } from "@/stores/data";

const dataStore = useDataStore();

const manualOpened: Ref<boolean> = ref(false);
const instructionOpened: Ref<boolean> = ref(false);
const detailsOpened: Ref<boolean> = ref(false);
const termsOpened: Ref<boolean> = ref(false);

defineProps({
  esim: Esim,
});
</script>
