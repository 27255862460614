import { defineStore } from "pinia";
import { Country, countryFromJson } from "@/stores/data";
import { ref, Ref } from "vue";
import query, { ErrorType, QueryMethod } from "@/query";
import router from "@/router";

export class Bundle {
  id: string;
  bundleSubId: string;
  description: string;
  dataAmount: number;
  duration: number;
  autostart: boolean;
  price: number;
  operatorId: number;
  countries: Country[];
  otherBundles: Bundle[];

  dataAmountGb = (): number => Math.round(this.dataAmount / 1024);
  priceUsd = (): number => this.price / 100;

  constructor(
    id: string,
    bundleSubId: string,
    description: string,
    dataAmount: number,
    duration: number,
    autostart: boolean,
    price: number,
    operatorId: number,
    countries: Country[],
    otherBundles: Bundle[]
  ) {
    this.id = id;
    this.bundleSubId = bundleSubId;
    this.description = description;
    this.dataAmount = dataAmount;
    this.duration = duration;
    this.autostart = autostart;
    this.price = price;
    this.operatorId = operatorId;
    this.countries = countries;
    this.otherBundles = otherBundles;
  }
}

export function bundleFromJson(json: Record<any, any>): Bundle {
  const countries: Country[] = [];
  const otherBundles: Bundle[] = [];

  if (Array.isArray(json.countries)) {
    json.countries.forEach((country) => {
      countries.push(countryFromJson(country));
    });
  }

  if (Array.isArray(json.otherBundles)) {
    json.otherBundles.forEach((bundle) => {
      otherBundles.push(bundleFromJson(bundle));
    });
  }

  return new Bundle(
    json.id.toString(),
    json.bundleSubId.toString(),
    json.description.toString(),
    parseInt(json.dataAmount),
    parseInt(json.duration),
    json.autostart === true,
    parseInt(json.price),
    parseInt(json.operatorId),
    countries,
    otherBundles
  );
}

export const useBundleStore = defineStore("bundle", () => {
  const bundles: Ref<Bundle[]> = ref([]);
  const countries: Ref<Country[]> = ref([]);
  const duration: Ref<number | undefined> = ref(undefined);
  const firstLoaded: Ref<boolean> = ref(false);

  function addBundle(bundle: Bundle): void {
    const bundleExistIndex: number = bundles.value.findIndex(
      (b: Bundle) => b.id === bundle.id
    );
    if (bundleExistIndex >= 0) {
      bundles.value[bundleExistIndex] = bundle;
    } else {
      bundles.value.push(bundle);
    }
  }

  async function getBundles(
    duration: number,
    countries: Country[]
  ): Promise<Bundle[]> {
    const response = await query({
      path: "bundle/get_all",
      method: QueryMethod.GET,
      params: {
        duration: duration,
        countries: countries.map((c: Country) => c.id),
      },
    });

    const responseBundles: Bundle[] = [];

    if (Array.isArray(response)) {
      bundles.value.splice(0, bundles.value.length);
      response.forEach((jsonBundle) => {
        const bundle: Bundle = bundleFromJson(jsonBundle);
        addBundle(bundle);
        responseBundles.push(bundle);
      });
      await router.push(
        `/?requestedCountries=${JSON.stringify(
          countries.map((c: Country) => c.iso)
        )}`
      );
    }

    firstLoaded.value = true;

    return responseBundles;
  }

  async function getBundleById(
    bundleId: string,
    requestedCountriesIds: number[]
  ): Promise<Bundle | null> {
    const response = await query({
      path: "bundle/get",
      method: QueryMethod.GET,
      params: { bundleId, requestedCountries: requestedCountriesIds },
    });

    if (response && !(response in ErrorType)) {
      const bundle: Bundle = bundleFromJson(response);
      addBundle(bundle);
      return bundle;
    } else {
      return null;
    }
  }

  async function checkBundleInfo(bundleId: string): Promise<boolean> {
    try {
      const response = await query({
        path: "bundle/check",
        params: { bundleId },
        method: QueryMethod.GET,
      });
      return !!response && response.success === true;
    } catch (e) {
      return false;
    }
  }

  function clearBundles() {
    bundles.value = [];
    countries.value = [];
    duration.value = undefined;
    firstLoaded.value = false;
  }

  return {
    bundles,
    countries,
    duration,
    firstLoaded,
    getBundles,
    getBundleById,
    clearBundles,
    checkBundleInfo,
  };
});
