<template>
  <PopupTemplate>
    <p class="popup__title">Delete account</p>
    <p class="popup__description">
      Are you sure you want to delete your account?
    </p>
    <div class="popup-buttons">
      <button class="btn btn-b btn-default" @click="close">Cancel</button>
      <button
        class="btn btn-b btn-default delete"
        @click="deleteAccount"
        :disabled="loading"
      >
        Delete
        <MainLoader v-if="loading" />
      </button>
    </div>
  </PopupTemplate>
</template>

<script setup lang="ts">
import PopupTemplate from "@/components/popups/PopupTemplate.vue";
import { useAppStore } from "@/stores/app";
import { ref, Ref } from "vue";
import MainLoader from "@/components/MainLoader.vue";
import { useUserStore } from "@/stores/user";

const appStore = useAppStore();
const userStore = useUserStore();
const loading: Ref<boolean> = ref(false);

const close = (): void => appStore.closePopup();
const deleteAccount = async (): Promise<void> => {
  loading.value = true;
  await userStore.deleteAccount();
  loading.value = false;
  close();
};
</script>

<style scoped lang="sass">
.delete
  background-color: red
  color: #ffffff
  &:hover
    background-color: red
    color: #ffffff
</style>
