import { defineStore } from "pinia";
import { Component, ref, Ref } from "vue";
import { useDataStore } from "@/stores/data";
import { useUserStore } from "@/stores/user";
import router from "@/router";

interface ShowPopupOptions {
  readonly component: Component;
  readonly props?: any;
}

export enum NotificationType {
  success,
  error,
  info,
}

export interface Notification {
  readonly text: string;
  readonly type: NotificationType;
}

interface ShowNotificationOptions {
  readonly notification: Notification;
  readonly milliseconds: number;
}

export const useAppStore = defineStore("app", () => {
  const popups: Ref<ShowPopupOptions[]> = ref([]);
  const notifications: Ref<Notification[]> = ref([]);
  const firstLoading: Ref<boolean> = ref(true);

  async function init(): Promise<void> {
    const dataStore = useDataStore();
    const userStore = useUserStore();

    try {
      await dataStore.getCountries();
      await dataStore.getData();
      await userStore.auth();
      // eslint-disable-next-line no-empty
    } catch (e) {}
    firstLoading.value = false;
  }

  function showPopup(options: ShowPopupOptions): void {
    popups.value.push(options);
  }

  function hideNotification(notification: Notification): void {
    const existIndex: number = notifications.value.indexOf(notification);
    if (existIndex >= 0) {
      notifications.value.splice(existIndex, 1);
    }
  }

  function showNotification(options: ShowNotificationOptions): void {
    notifications.value.push(options.notification);
    setTimeout(
      () => hideNotification(options.notification),
      options.milliseconds
    );
  }

  function closePopup(): void {
    if (popups.value.length) {
      popups.value.splice(popups.value.length - 1, 1);
    } else {
      const fullPath = String(router.currentRoute.value.fullPath);
      if (fullPath.includes("#auth")) {
        const splitPath: string[] = fullPath.split("#");
        if (splitPath.length > 1) {
          router.push(splitPath[0]);
        } else {
          router.push(fullPath);
        }
      }
    }
  }

  return {
    popups,
    notifications,
    firstLoading,
    init,
    showPopup,
    closePopup,
    showNotification,
  };
});
