<template>
  <button
    class="data-plans-slider-item"
    :class="{ primary }"
    @click="goBundleDetails"
  >
    <div class="data-plans-item-head-group">
      <div class="data-plans-item-head">
        <div class="gb-info">
          {{ bundle.dataAmountGb() }} GB {{ bundle.duration }} days
        </div>
        <BundleCountries
          :countries="bundle?.countries || []"
          :requested-countries="requestedCountries || []"
        />
      </div>
      <span class="price-main">$ {{ bundle.priceUsd() }}</span>
    </div>
    <div class="b-info-gb">
      <div class="info-gb">
        <p class="text-center">This is the good plan for your needs</p>
      </div>
      <button v-if="bundle" class="view-btn" @click="goBundleDetails">
        View offer
      </button>
      <p v-if="primary" class="b-info__recommended">Recommended plan</p>
    </div>
  </button>
</template>

<script setup lang="ts">
import { defineProps, PropType } from "vue";
import BundleCountries from "@/components/bundle/BundleCountries.vue";
import { Bundle } from "@/stores/bundle";
import { Country } from "@/stores/data";
import router from "@/router";

const props = defineProps({
  bundle: Bundle,
  requestedCountries: Array as PropType<Array<Country>>,
  primary: {
    type: Boolean,
    default: false,
  },
});

const goBundleDetails = () => {
  router.push(
    `/bundle/${props.bundle?.id}?requestedCountries=${JSON.stringify(
      props.requestedCountries?.map((c) => c.iso)
    )}`
  );
};
</script>

<style scoped lang="sass"></style>
